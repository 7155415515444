<template>
  <a-card class="main-content">
    <template slot="title">
      <span class="table-title">版本列表</span>
      <a-button type="primary" icon="plus" @click="showModal()">新增</a-button>
    </template>
    <!-- 列表 -->
    <a-table
      :loading="loading"
      row-key="kid"
      :columns="columns"
      :data-source="datalist"
      :pagination="pagination"
      @change="onPageChange">
      <span slot="file_path" slot-scope="url">
        <a-button type="primary" @click="download(url)">点击下载</a-button>
      </span>
      <span slot="action" slot-scope="record">
        <a-popconfirm
          title="您确认要删除该版本吗？"
          @confirm="del(record.kid)">
          <a-button type="link">删除</a-button>
        </a-popconfirm>
      </span>
    </a-table>
    <!-- 弹框 -->
    <a-modal
      title="新增版本"
      width="540px"
      :visible="dialog"
      :ok-text="okBtnText"
      :confirm-loading="confirmLoading"
      :mask-closable="mask"
      @ok="add()"
      @cancel="() => {
        dialog = false
        $refs.modalForm.resetFields()
      }">
        <a-form-model
          ref="modalForm"
          :model="form"
          :rules="rules"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 19 }">
          <a-form-model-item label="类别" prop="sort">
            <a-radio-group v-model="form.sort">
              <a-radio value="pad">平板</a-radio>
              <a-radio value="hand">手持</a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="版本序号" prop="ver_code">
            <a-input v-model="form.ver_code" placeholder="请输入版本序号"/>
          </a-form-model-item>
          <a-form-model-item label="版本编码" prop="ver_name">
            <a-input v-model="form.ver_name" placeholder="请输入版本编码"/>
          </a-form-model-item>
          <a-form-model-item label="上传安装包" prop="file_path" :autoLink="false" ref="uploadItem">
            <a-upload
              :custom-request="uploadFile"
              :show-upload-list="false">
              <a-button 
                :loading="uploading"
                type="primary" 
                icon="upload">{{ form.file_path ? '重新上传' : '上传'}}</a-button>
            </a-upload>
            <div class="upload-list" v-if="form.file_path">
              <img src="../../assets/images/apk.png" class="icon-android">
              <span class="apk-name">安装包.apk</span>
            </div>
          </a-form-model-item>
          <a-form-model-item label="描述" prop="note">
            <a-textarea v-model="form.note" :autoSize="{ minRows: 3}" placeholder="版本描述"></a-textarea>
          </a-form-model-item>
          <a-row>
            <a-col :span="12">
              <a-form-model-item 
                label="强制" 
                :label-col="{ span: 10 }" 
                :wrapper-col="{span: 14 }"
                prop="is_force">
                <a-switch v-model="form.is_force" ></a-switch>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item 
                label="状态" 
                :label-col="{ span: 10 }" 
                :wrapper-col="{span: 14 }"
                prop="status">
                <a-switch v-model="form.status"></a-switch>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </a-modal>
  </a-card>
</template>

<script>
export default {
  data () {
    return {
      loading: false,
      searchForm: {
        page: 1,
        list_rows: 30
      },
      columns: [
        { title: '序号', key: 'kid', dataIndex: 'kid', align: 'center', width: 65, customRender: (text, record, index) =>`${(this.pagination.current - 1) *this.pagination.pageSize + index + 1}` },
        { title: '版本序号', key: 'ver_code', dataIndex: 'ver_code', align: 'center' },
        { title: '版本编码', key: 'ver_name', dataIndex: 'ver_name', align: 'center' },
        { title: '描述', key: 'note', dataIndex: 'note', align: 'center' },
        { title: '下载地址', key: 'file_path', dataIndex: 'file_path', scopedSlots: { customRender: 'file_path' }, align: 'center' },
        { title: '类别', key: 'sort', dataIndex: 'sort', align: 'center' },
        { title: '强制', key: 'is_force', dataIndex: 'is_force', align: 'center' },
        { title: '状态', key: 'status', dataIndex: 'status', align: 'center' },
        { title: '添加时间', key: 'addtime', dataIndex: 'addtime', align: 'center' },
        { title: '操作', key: 'action', scopedSlots: { customRender: 'action' }, align: 'center' }
      ],
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        current: 1,
        pageSize: 30,
        total: 0,
        showTotal: total => `总共 ${total} 条`,
        pageSizeOptions: ['10', '20', '30', '50']
      },
      datalist: [],
      // 弹框
      dialog: false,
      confirmLoading: false,
      uploading: false,
      mask: true,
      okBtnText: '确 定',
      form: {
        ver_code: '', 
        ver_name: '', 
        note: '', 
        file_path: '', 
        sort: 'pad', 
        is_force: true, 
        status: true
      },
      rules: {
        ver_code: [{ required: true, message: '该项为必填项，请输入', trigger: 'blur' }],
        ver_name: [{ required: true, message: '该项为必填项，请输入', trigger: 'blur' }],
        note: [],
        file_path: [{ required: true, message: '请上传apk文件', trigger: 'change' }],
        sort: [{ required: true, message: '该项为必填项，请选择', trigger: 'change' }],
        is_force: [{ required: true, message: '该项为必填项，请选择', trigger: 'change' }],
        status: [{ required: true, message: '该项为必填项，请选择', trigger: 'change' }],
      }
    }
  },
  mounted () {
    this.getVersionList()
  },
  methods: {
    getVersionList () {
      this.loading = true 
      this.api.getVersionList(this.searchForm).then(res => {
        this.datalist = res.data.data 
        this.pagination.total = res.data.total
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    onPageChange (pagination) {
      this.pagination = pagination
      this.searchForm.page = pagination.current
      this.searchForm.list_rows = pagination.pageSize
      this.getVersionList()
    },
    showModal() {
      this.dialog = true 
      this.$nextTick(() => {
        this.$refs.modalForm.resetFields()
      })
    },
    uploadFile (file) {
      this.uploading = true
      this.okBtnText = '上传中'
      this.confirmLoading = true
      this.mask = false  // 取消遮罩关闭
      this.api.uploadFile(file.file, 'app').then(res => {
        this.uploading = false
        this.form.file_path = res.data.data.file_path
        this.okBtnText = '确 定'
        this.confirmLoading = false
        this.mask = true
        this.$refs.uploadItem.clearValidate()
      }).catch(() => {
        this.mask = true
        this.uploading = false
        this.okBtnText = '确 定'
        this.confirmLoading = false
      })
    },
    add () {
      this.$refs.modalForm.validate(valid => {
        if (valid) {
          // 按钮loading
          this.confirmLoading = true;

          let param = { ...this.form }
          param.is_force = this.form.is_force ? 1: 0;
          param.status = this.form.status ? 1: 0;

          this.api.addVersion(param).then(res => {
            this.$message.success(res.data.msg)
            this.getVersionList()
            this.dialog = false 
            this.confirmLoading = false
          }).catch(() => {
            this.confirmLoading = false
          })
        } else {
          return false
        }
      })
    },
    del (versionId) {
      this.api.delVersion({kid: versionId}).then(res => {
        this.$message.success(res.data.msg)
        this.getVersionList()
      })
    },
    download (url) {
      let aDom = document.createElement('a');
      aDom.setAttribute('download', '');
      aDom.setAttribute('href', url)
      aDom.click()
    }
  }
}
</script>

<style lang="less" scoped>
.upload-list {
  display: inline-block;
  margin-left: 30px;
  height: 32px;
  .icon-android {
    margin-right: 10px;
    width: 24px;
    height: 24px;
  }
  .apk-name {
    display: inline-block;
    vertical-align: middle;
  }
}
</style>